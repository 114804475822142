import NopeObject from './NopeObject';
import NopeString from './NopeString';
import NopeNumber from './NopeNumber';
import NopeBoolean from './NopeBoolean';
import NopeDate from './NopeDate';
import NopeReference from './NopeReference';
import NopePrimitive from "./NopePrimitive";
import NopeAny from "./NopeAny";
import NopeFile from "./NopeFile";

const NopeObjectConstructor = () => new NopeObject();
const NopeAnyConstructor = () => new NopeAny();
const NopeStringConstructor = () => new NopeString();
const NopeNumberConstructor = (message?: string) => new NopeNumber(message);
const NopeBooleanConstructor = () => new NopeBoolean();
const NopeDateConstructor = (dateFormat?: string, message?: string) => new NopeDate(dateFormat, message);
const NopeFileConstructor = (message?: string) => new NopeFile(message);
const NopeReferenceConstructor = (key: string) => new NopeReference(key);

const Nope = {
  any: NopeAnyConstructor,
  object: NopeObjectConstructor,
  string: NopeStringConstructor,
  number: NopeNumberConstructor,
  boolean: NopeBooleanConstructor,
  date: NopeDateConstructor,
  file: NopeFileConstructor,
  ref: NopeReferenceConstructor,
};

export {
  Nope,
  NopeAny as any,
  NopeObjectConstructor as object,
  NopeNumberConstructor as number,
  NopeStringConstructor as string,
  NopeBooleanConstructor as boolean,
  NopeDateConstructor as date,
  NopeFileConstructor as file,
  NopeReferenceConstructor as ref,
};

export default Nope;
