import NopePrimitive from './NopePrimitive';
import { Rule } from './types';
import NopeReference from "./NopeReference";
import {resolveNopeRef} from "./utils";

class NopeNumber extends NopePrimitive<number> {
  private message = 'The field is not a number';

  public integer(message = 'Input must be an integer') {
    const rule: Rule<number> = entry => {

      if (this.isEmpty(entry)) {
        return;
      }

      if (entry !== Math.floor(entry as number)) {
        return message;
      }
    };

    return this.test(rule);
  }

  /**
   * @deprecated alias for greaterThan()
   */
  public min(size: number | NopeReference, message?: string) {
    return this.greaterThan(size, message);
  }

  /**
   * @deprecated alias for lessThan()
   */
  public max(size: number | NopeReference, message?: string) {
    return this.lessThan(size, message);
  }

  public greaterThan(size: number | NopeReference, message?: string) {
    if (message === undefined) {
      if (size instanceof NopeReference) {
        message = 'Input is too small';
      } else {
        message = `Input must be greater than ${size}`;
      }
    }

    const rule: Rule<number> = entry => {
      if (this.isEmpty(entry)) {
        return;
      }

      if ((entry as number) <= resolveNopeRef(size)) {
        return message;
      }
    };

    return this.test(rule);
  }

  public lessThan(size: number | NopeReference, message?: string) {
    if (message === undefined) {
      if (size instanceof NopeReference) {
        message = 'Input is too big';
      } else {
        message = `Input must be less than ${size}`;
      }
    }

    const rule: Rule<number> = entry => {
      if (this.isEmpty(entry)) {
        return;
      }

      if ((entry as number) >= resolveNopeRef(size)) {
        return message;
      }
    };

    return this.test(rule);
  }

  public atLeast(constraint: number | NopeReference, message?: string ) {
    if (message === undefined) {
      if (constraint instanceof NopeReference) {
        message = 'Input is too small';
      } else {
        message = `Input must be at least ${constraint}`;
      }
    }
    const rule: Rule<number> = (entry, context) => {
      if (this.isEmpty(entry)) {
        return;
      }

      let value = resolveNopeRef(constraint, context);

      if ((entry as number) < value) {
        return message;
      }
    };

    return this.test(rule);
  }

  public atMost(constraint: number | NopeReference, message?: string) {
    if (message === undefined) {
      if (constraint instanceof NopeReference) {
        message = 'Input is too large';
      } else {
        message = `Input must be at most ${constraint}`;
      }
    }

    const rule: Rule<number> = (entry, context) => {
      if (this.isEmpty(entry)) {
        return;
      }

      console.log("AT MOST: Context", context);
      let value = resolveNopeRef(constraint, context);

      if ((entry as number) > value) {
        return message;
      }
    };

    return this.test(rule);
  }

  public positive(message = 'Input must be positive') {
    return this.greaterThan(0, message);
  }

  public negative(message = 'Input must be negative') {
    return this.lessThan(0, message);
  }

  public validate(entry?: any, context?: object | undefined): string | undefined {
    const value = !!entry ? Number(entry) : entry;

    if (!this.isEmpty(value) && Number.isNaN(value)) return this.message;

    return super.validate(value, context);
  }

  public constructor(message: string = 'The field is not a valid number') {
    super();
    this.message = message;
  }
}

export default NopeNumber;
