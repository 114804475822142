
export class CurrencyTool {
    static ceil(value: number | string): number {
        let cents = Number(value) * 100;

        return Math.ceil(cents) / 100;
    }

    static round(value: number | string): number {
        let cents = Number(value) * 100;

        return Math.round(cents) / 100;
    }

    static format(value: number | string): string {
        return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(Number(value));
    }

}