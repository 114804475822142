import React from 'react';
import './body.styles.scss';
import beefIcon from '../../assets/iconMenu_beef.svg';
import lambIcon from '../../assets/iconMenu_lamb.svg';
import traceImg from '../../assets/trace2.svg';
import mike from '../../assets/Mike_meat.svg';
import meat5 from '../../assets/meat_5kg.svg';
import qrexample from '../../assets/qr_example.svg';


export const Body = () => {

    return (
        <div>



    
        <div className="blackSection" style={{paddingTop: '30px', paddingBottom: '20px'}}>
        <div className="container text-center">
            <beef />
            <img src={beefIcon} alt="Red meat company beef icon" className="img-fluid"/> 
            <img src={lambIcon} alt="Lamb meat company beef icon" className="img-fluid"/> 
        </div>
      </div>

        <div className="selectBar">
        </div>





        


        <div className="beefSection">
            <div className="container text-center col-md-8 offset_md-1">
                <h1>SPECIAL PROMOTION</h1>
                <p className="col-md-10 offset-md-1">
                    <span style={{color: '#ce202e', fontSize: '26px'}}>Special Offer:</span><br></br><span style={{background: '#ce202e', padding: '5px', lineHeight: '40px'}}>20% DISCOUNT</span><br></br>Family meat packs! A
                    limited supply of Prime Black Angus grass-fed beef available in 5kg or 10kg of selected cuts. <br></br>
                    <span style={{color: '#ce202e'}}>(Frozen Packs)</span>
                    <br></br><br></br>
                    Available only in Metro Melbourne, regional Victoria, Sydney and Canberra. <br></br>$12.95 flat delivery fee. </p>
                <br></br>
                <p className="col-md-10 offset-md-1">The special offer is to trial our traceability system from farm to
                    fork. <br></br>On delivery, you can scan your QR code and see where your fresh beef came from.<br></br>It's
                    traceability at your fingertips!
                    <br></br>
                    T&amp;Cs apply.
                </p>
                <div className="col-md-6 offset-md-3 mt-3"> 
                <br></br>
                    <img src={traceImg} alt="trace images" className="img-fluid" style={{width: '100%',}} />
                </div>
            </div>
        </div>



        <div className="element-layout" style={{marginTop: '0px', paddingTop: '20px',paddingBottom: '20px'}}>
           
            {/*<div className="container">
                <div className="col-md-12">
                <div style={{display: 'grid', gridTemplateColumns: '1fr', alignItems: 'center'}}>
                    <div className="col-md-4 offset-md-4"><br></br><img src={meat5} alt="Family 5kg Meat Pack" className="img-fluid col-md-12" /></div>
                    <div className="col-md-8 offset-md-2"> <h4 style={{paddingTop: '0px', marginTop: '5px', marginBottom: '10px'}}>Sample of 5kg family pack</h4>
                    <p style={{fontSize: '14px'}}>We are trying to keep the price down so everyone can enjoy some specialty cuts. We can take special requests however price might change. <br></br><br></br>
TOPSIDE ROAST – 1.5kg roast 
STEAKS – PORTERHOUSE, RUMP, EYE FILLET, STEWING STEAK, T-BONE, GRAVY STEAK, SCHNITZEL
(a Mix of specialty cuts and every day cooking cuts)  1.4kg
SAUSAGES 800gm 
Sti-fry 400gm
MINCE 900gm
                        </p><br></br><br></br>
                        </div>
                 </div>
                </div>
                <div className="col-md-12">
                <div style={{display: 'grid', gridTemplateColumns: '1fr', alignItems: 'center'}}>
                    <div className="col-md-4 offset-md-4"><img src={mike} alt="Micheal" className="img-fluid col-md-12" /></div>
                    <div> <h4 style={{paddingTop: '0px', marginTop: '5px', marginBottom: '0px'}}>Supporting a local farmer</h4><p style={{fontSize: '14px'}}>Michael from Bass Coast</p></div>
                 </div>
                </div>
    </div>*/}

<div className="container">
                <div className="col-md-12">
                <div className="mickSection" style={{display: 'grid', gridTemplateColumns: '1fr 2fr', alignItems: 'center'}}>
                    <div className=""><br></br><img src={meat5} alt="Family 5kg Meat Pack" className="img-fluid col-md-12" /></div>
                    <div className="col-md-12 offset-md-0"> <h3 style={{paddingTop: '0px', marginTop: '5px', marginBottom: '10px', textAlign: 'left'}}>SAMPLE OF 5KG FAMILY PACK</h3>
                    <p style={{fontSize: '18px', textAlign: 'left'}}> 
TOPSIDE ROAST – 1.5kg roast <br></br>
STEAKS – PORTERHOUSE, RUMP, EYE FILLET, STEWING STEAK, T-BONE, GRAVY STEAK, SCHNITZEL <br></br>(a Mix of specialty cuts and every day cooking cuts)  1.4kg <br></br>
SAUSAGES 800gm  <br></br>
Sti-fry 400gm <br></br>
MINCE 900gm<br></br><br></br>We aim to keep prices low, so everyone can enjoy selected cuts.<br></br>Prices may change with special requests.
                        </p>
                        </div>
                 </div>
                 <br></br>
                </div>
                <div className="col-md-12">
                <div className="mickSection" style={{display: 'grid', gridTemplateColumns: '2fr 1fr', alignItems: 'center'}}>
                
                <div> <h3 style={{paddingTop: '0px', marginTop: '5px', marginBottom: '0px', textAlign: 'right'}}>SUPPORTING A LOCAL FARMER </h3><p style={{fontSize: '18px', textAlign: 'right'}}>Michael from Bass Coast</p></div>

                <div className=""><img src={mike} alt="Micheal Farmer Photo" className="img-fluid col-md-12" /></div>
                    
                  
                 </div>
                 <br></br>
                 
                </div>
                <div className="col-md-12">
<div className="mickSection"
    style={{display: 'grid', gridTemplateColumns: '1fr 2fr', alignItems: 'center'}}>
    <div className=""><br></br><img src={qrexample} alt="Family 5kg Meat Pack"
            className="img-fluid col-md-12" /></div>
    <div className="col-md-12 offset-md-0">
        <h3 style={{paddingTop: '0px', marginTop: '5px', marginBottom: '10px', textAlign: 'left'}}>
        NEXT GENERATION TRACEABILITY</h3>
        <p style={{fontSize: '18px', textAlign: 'left'}}>
        AXIchain QR code technology, including food safety regulation - so you can scan your pack on arrival and see exactly where it came from. 

        <a href="https://www.axichain.io/" target="_blank" className={'regButton'}><button type="button" className="btn"
                    style={{background: 'transparent', color: '#ffffff', border: '1px solid #ffffff', margin: '20px 0px', paddingTop: '17px', paddingBottom: '17px'}}>FIND OUT MORE ABOUT AXICHAIN
                </button></a>
        </p>

       
    </div>
</div>
<br></br>
</div>
            </div>



        </div>


        <div className="blackSection pt-5 pb-5" style={{overflow: 'hidden', paddingTop: '70px', paddingBottom: '40px'}}>
            {/*<div className="limitedOfferBar">LIMITED OFFER</div>*/}
            <div className="container">
                <h1 className="text-center text-white mb-5">Prime Black Angus</h1>
                <div className="row">
                    <div className="col-md-4 offset-md-2 text-center text-white borderRight">
                        <h4 style={{color: '#ce202e',fontSize: '28px'}}>REGISTER INTEREST</h4>
                        <p className="price">$24.95<span>/kg</span></p>
                        {/* <p>Intended delivery w/c 21st December*</p>*/}
                    </div>
                    <div className="col-md-4 text-center text-white">
                        <h4 style={{color: '#ce202e',fontSize: '28px', position: 'relative'}}>PAY NOW<br></br><span style={{position: 'absolute', fontSize: '12px', textAlign: 'center', left: '0px', width: '100%'}}>and you will  receive</span></h4>
                        <p className="price">20% <span>DISCOUNT</span></p>
                        <p>Price: $19.96/kg* <span className="limiteSmallBar">limited offer</span></p>
                    </div>
                    {/*<div className="col-md-4 text-center text-white">
                        <h4 style={{color: '#ce202e',fontSize: '28px'}}>WITH DEPOSIT</h4>
                        <p className="price">$23.95<span>/kg</span></p>
                        <p>Dispatch priority*</p>
                    </div>*/}
                </div>
            </div>
        </div>

        
      




        </div>
    );

}