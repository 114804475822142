import NopePrimitive from './NopePrimitive';
import { Rule } from './types';
import NopeReference from "./NopeReference";
import {resolveNopeRef} from "./utils";

// Not the traditional KB, MB, GB
const KB = 1000;
const MB = 1000 * KB;
const GB = 1000 * MB;

class NopeFile extends NopePrimitive<File> {
  private message = 'The field is not a number';

  /**
   * @deprecated alias for lessThan()
   */
  public max(size: number | NopeReference, message?: string) {
    return this.atMost(size, message);
  }

  protected formatSize = (size: number) => {
    if (size >= GB) {
      return `${size / GB} GB`;
    } else if (size >= MB) {
      return `${size / MB} MB`;
    } else if (size >= KB) {
      return `${size / KB} KB`;
    } else {
      return `${size} bytes`;
    }
  };

  public atMost(size: number | NopeReference, message?: string) {
    if (message === undefined) {
      if (size instanceof NopeReference) {
        message = 'File is too big';
      } else {
        message = `File must be at most ${this.formatSize(size)}`;
      }
    }

    const rule: Rule<File> = entry => {
      if (this.isEmpty(entry)) {
        return;
      }

      if (((<File> entry).size) >= resolveNopeRef(size)) {
        return message;
      }
    };

    return this.test(rule);
  }


  public validate(entry?: File | undefined, context?: object | undefined): string | undefined {
    const value = entry;

    if (!this.isEmpty(value) && !(value instanceof File)) return this.message;

    return super.validate(value, context);
  }

  public constructor(message: string = 'The field is not a valid file') {
    super();
    this.message = message;
  }
}

export default NopeFile;
