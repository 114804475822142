import {Field, ErrorMessage} from "@kuzmycz/react-form-ula";

const TextFormField = ({label, name, ...rest}) => {
    return (<div className='text-form-field'>
        <label>{label}
            <Field className='text-field' name={name} {...rest}/>
            <ErrorMessage name={name} className={'field-error'}/>
        </label>
    </div> )
}

export default TextFormField;