import React from 'react';
import logo from '../../assets/rmcLogo.svg';
import banner from '../../assets/promoBanner.png';

export const ReservationCompletePage = ({receipt}) => {

    return (
        <React.Fragment>
             <div className="header">
                <div className="container">
                <br></br><br></br><br></br>
                <div className="text-center col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1">
                    <a href="index.html"><img className="img-fluid" src={logo} alt="RMC logo"
                        style={{position: 'relative', top: '10px', marginBottom: '20px', width: '60%'}}/></a>
                    <img className="img-fluid" src={banner} alt="banner" style={{position: 'relative', top: '10px', maxWidth: '100%'}}/>
                </div>
                <br></br><br></br><br></br>
                </div>
            </div>

            <div className="blackSection pt-5 pb-5" style={{overflow: 'hidden'}}>
                <div className="container">
                    <h1 className="text-center text-white mb-5">Thank you!</h1>
                    <h3 className="text-center text-white mb-5">You have reserved your pack.</h3>
                    <h3 className="text-center text-white mb-5">Your credit card has been debited ${receipt.deposit}</h3>
                    <h3 className="text-center text-white mb-5">Your receipt number is {receipt.reference}</h3>
                </div>
            </div>
        </React.Fragment>

    );
}







