import React, {useState} from 'react';
import { Form } from '@kuzmycz/react-form-ula';
import TextFormField from "../../components/from-fields/text-form-field/text-form-field.component";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Header} from "../../components/header/header.component";
import {Spinner} from "../../components/spinner/spinner.component";
import {CurrencyTool} from "../../util/currency-tool";
import {validator} from "./full-checkout-page.validations";
import './full-checkout-page.styles.scss'
import { growl } from '@crystallize/react-growl';
import {ShippingAddress} from "../../components/shipping-address/shipping-address.component";

export const FullCheckoutPage = ({cart, onSuccess, onFailure}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);

    const createPostPayload = async (payload) => {
        const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };

        /*
        turning off authentication
        */
        // await context.authenticationHandler(headers);

        return {
            method: 'POST',
            cache: 'no-cache',
            headers: headers,
            redirect: 'follow',
            body: JSON.stringify(payload)
        };
    };

    const postPayload = async(payload) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_AXICHAIN_API}/rmc/order`, payload);

            console.log("RESPONSE", await response.clone().json());
            if (!!response.ok) {
                let result = await response.json();

                return result;
            } else {
                return {
                    type: 'ERROR',
                    name: 'Network Error',
                    description: response.statusText
                }
            }
        } catch (e) {
            return {
                type: 'ERROR',
                name: 'Network Error',
                description: e
            };
        }

    }
    console.log('PAGE reloaded!!')

    const createBillingDetails = (values) => ({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        street: values.street,
        city: values.city,
        state: values.state,
        postcode: values.postcode,
        phone: values.phone
    });

    const createShippingDetails = (values, defaultShipping) => {
        let shipping = (values.shippingSame)? ({
            ...defaultShipping,
            instructions: values.shippingInstructions,
        }) : ({
            email: values.shipping_email,
            firstName: values.shippingFirstName,
            lastName: values.shippingLastName,
            street: values.shippingStreet,
            city: values.shippingCity,
            state: values.shippingState,
            postcode: values.shippingPostcode,
            phone: values.shippingPhone,
            instructions: values.shippingInstructions,
        });

        return shipping;
    };

    const createPayload = (value, items, paymentMethod) => {
        let billing = createBillingDetails(value);
        let shipping = createShippingDetails(value, billing);


        return ({
            billing: billing,
            shipping: shipping,
            items: cart.items,
            paymentToken: paymentMethod?.id,
        });
    }

    const handleSubmit = async (value) => {
        console.log("VALUE", value);
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }


        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        setProcessing(true);

        if (error) {
            console.log('[error]', error);
            setProcessing(false);
        } else {
            console.log("Billing", value);
            const payload = await createPostPayload(createPayload(value, cart.items, paymentMethod));

            let result = await postPayload(payload);


            if (result.type === 'RECEIPT') {
                setProcessing(false);
                onSuccess && onSuccess(result);
            } else {
                setProcessing(false);
                onFailure && onFailure(result);
            }
        }
    };

    const totalAmount = () => cart.items.reduce((p, c) => p + (c.quantity * c.unitPrice), 0);
    const discountAmount = () => CurrencyTool.round(totalAmount() * 0.20);
    const amountDue = () => totalAmount() + 12.95 - discountAmount();

// Regular
    const growlError = async (errors) =>  {
        console.log(errors);
        let keys = Object.keys(errors) || [];
        let message = errors[keys[0]];
        await growl({
            title: 'Error',
            message: message,
            type: 'error'
        });
    };

    return (
        <div className='page'>
            <Spinner show={processing} message={'Processing order'}/>
            <Header/>
            <Form onSubmit={handleSubmit} initialValues={{shippingSame: true}}validator={(values) => {
                console.log('VALUES: ', values);
                return validator.validate(values)
            }} onSubmitError={growlError}>
                <h1>Order Page</h1>
                <h3>Contact Details</h3>
                <div className='shipping-details'>
                    <div className="emailField"><TextFormField name='email' type='text' placeholder='Email Address'/></div>
                </div>
                <h3>Billing Details</h3>
                <div className='shipping-details'>
                    <div className="firstNameField">
                        <TextFormField placeholder='First Name' name='firstName' type='text'/>
                    </div>
                    <div className="lastNameField"><TextFormField placeholder='Last Name' name='lastName' type='text'/></div>
                    <div className="streetField"><TextFormField placeholder='Street' name='street' type='text'/></div>
                    <div className="cityField"><TextFormField placeholder='City' name='city' type='text'/></div>
                    <div className="stateField"><TextFormField placeholder='State' name='state' type='text'/></div>
                    <div className="postcodeField"><TextFormField placeholder='Postcode' name='postcode' type='text'/></div>
                    <div className="phoneField"><TextFormField placeholder='Phone' name='phone' type='text'/></div>
                </div>
                <ShippingAddress />
                <div className='shipping-details'>
                    <div className="instructionField"><TextFormField placeholder='Special Instructions' name='shippingInstructions' type='text'/></div>
                </div>
                <h3>Payment Details<br/>(Discounted 20% plus transport cost)</h3>
                <div className='cart'>
                    <div class={'cartHeaderTitle'}>
                        <div>Item</div>
                        <div>Quantity</div>
                        <div>Price per Pack</div>
                        <div>Pay Now</div>
                    </div>
                    {
                        cart.items.map((item) =>
                            <div className={`row cartItem`} key={item.code}>
                                <div className={`name cartName`}>{item.name}</div>
                                <div className={`name cartQty`}>{item.quantity}</div>
                                <div className={`name cartPrice`}>{CurrencyTool.format(item.unitPrice)}</div>
                                <div className={`name cartTotal`}>{CurrencyTool.format(CurrencyTool.ceil(item.unitPrice * item.quantity))}</div>
                            </div>
                        )
                    }
                    <div className={`row cartItem fullAmountLine`}>
                    <div></div><div></div>
                        <div className={`name cartName`}>Discount <span>20% off</span></div>
                        <div className={`name cartTotal`}>{CurrencyTool.format(-discountAmount())}</div>
                    </div>
                    <div className={`row cartItem fullAmountLine`}>
                        <div></div><div></div>
                        <div className={`name cartName`}>Shipping</div>
                        <div className={`name cartTotal`}>{CurrencyTool.format(12.95)}</div>
                    </div>
                    <div className={`row cartItem fullTotalAmountLine`}>
                    <div></div><div></div>
                        <div className={`name cartName`}><strong>Amount Due</strong></div>
                        <div className={`name cartTotal`}>{CurrencyTool.format(amountDue())}</div>
                    </div>

                </div>
                <p style={{fontSize: '14px'}}><br></br></p>
                <div className='paymentWrappers'>
                <div class='cardLogos'></div>
                <div className='payment-details'>
                    {
                        !processing && <CardElement />
                    }

                </div>
                <button type='submit' className={`btn payButton`}>Make Payment</button>
                {
                    // <CacheLog display={true}></CacheLog>
                }
                </div>
            </Form>
        </div>
    )
}