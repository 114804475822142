import NopePrimitive from './NopePrimitive';
import NopeReference from './NopeReference';
import { Rule } from './types';
import moment from 'moment';
import {isString} from "./utils";


type T = string | number | moment.Moment | undefined | null;

class NopeDate extends NopePrimitive<T> {
  private message: string;
  private dateFormat: string;

  public before(beforeDate: T | NopeReference, message?: string) {
    if(message === undefined) {
      message = (beforeDate instanceof NopeReference) ?
          `Date must be before ${beforeDate}` :
          `Date must be before ${moment(beforeDate as any).format("DD MMM YYYY")}`;
    }

    const rule: Rule<T> = (entry: T, context) => {
      if (this.isEmpty(entry)) {
        return;
      }

      const value = moment(entry as any);

      const resolvedBeforeDate =
        beforeDate instanceof NopeReference && context ? context[beforeDate.key] : beforeDate;

      if (value.isSameOrAfter(moment(resolvedBeforeDate))) {
        return message;
      }
    };

    return this.test(rule);
  }

  public to(beforeDate: T | NopeReference, message?: string) {
    if(message === undefined) {
      message = (beforeDate instanceof NopeReference) ?
          `Date must be before ${beforeDate}` :
          `Date must be before ${moment(beforeDate as any).format("DD MMM YYYY")}`;
    }

    const rule: Rule<T> = (entry: T, context) => {
      if (this.isEmpty(entry)) {
        return;
      }

      const value = moment(entry as any);

      const resolvedBeforeDate =
          beforeDate instanceof NopeReference && context ? context[beforeDate.key] : beforeDate;

      if (value.isAfter(moment(resolvedBeforeDate))) {
        return message;
      }
    };

    return this.test(rule);
  }

  public after(afterDate: T | NopeReference, message?: string) {
    if(message === undefined) {
      message = (afterDate instanceof NopeReference) ?
          `Date must be after ${afterDate}` :
          `Date must be after ${moment(afterDate as any).toDate()}`
    }

    const rule: Rule<T> = (entry: T, context) => {
      if (this.isEmpty(entry)) {
        return;
      }

      const value = moment(entry as any);

      const resolvedAfterDate =
        afterDate instanceof NopeReference && context ? context[afterDate.key] : afterDate;

      if (value.isSameOrBefore(moment(resolvedAfterDate))) {
        return message;
      }
    };

    return this.test(rule);
  }

  public from(constraint: T | NopeReference, message?: string) {
    if(message === undefined) {
      message = (constraint instanceof NopeReference) ?
          `Date must be after ${constraint}` :
          `Date must be after ${moment(constraint as any).toDate()}`
    }

    const rule: Rule<T> = (entry: T, context) => {
      if (this.isEmpty(entry)) {
        return;
      }

      const value = moment(entry as any);

      const resolvedAfterDate =
          constraint instanceof NopeReference && context ? context[constraint.key] : constraint;

      if (value.isBefore(moment(resolvedAfterDate))) {
        return message;
      }
    };

    return this.test(rule);
  }

  public validate(entry?: any, context?: object | undefined): string | undefined {
    let value = entry;

    if (this.isEmpty(entry)) {
      value = entry;
    } else {
      const date = (isString(entry)) ? moment(entry, this.dateFormat, true) : moment(entry);

      if(date.isValid()) {
        value = date
      } else {
        return this.message;
      }
    }

    return super.validate(value, context);
  }

  public constructor(dateFormat = 'YYYY-MM-DD', message = 'The field is not a valid date') {
    super();

    this.message = message;
    this.dateFormat = dateFormat;
  }
}

export default NopeDate;
