import React from 'react';
import "./spinner.styles.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Spinner = ({show, message}) => {

    return (
        <React.Fragment>
            { show && <div className='backdrop'>
                <div className='spinner-panel' style={{position: 'relative', top: '48vh'}}>
                    {message}
                    <FontAwesomeIcon icon="spinner" spin={true}/>
                </div>
            </div>}
        </React.Fragment>
    );
};