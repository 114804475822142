import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';
import MainPageComponent from './pages/main-page/main-page.component';
import {CardElement, Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import {
    BrowserRouter as Router,
    Switch,
    Route, useHistory,
} from "react-router-dom";
import {CheckoutPage} from "./pages/checkout-page/checkout-page.component";
import {Cart} from "./util/cart";
import {ReservationCompletePage} from "./pages/reservation-complete-page/reservation-comlete-page";
import {ReservationFailedPage} from "./pages/reservation-failed-page/reservation-failed-page";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {BlockchainViewerPage} from "./pages/blockchain-viewer-page/blockchain-viewer-page.component";
import {FullCheckoutPage} from "./pages/full-checkout-page/full-checkout-page.component";
import { GrowlScene } from '@crystallize/react-growl';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


function App() {
    library.add(fab, faSpinner);

    return (
    <div className="App">
        <GrowlScene />
        <Router>
            <Elements stripe={stripePromise}>
                <Routes/>
            </Elements>
        </Router>
    </div>
  );
}

const Routes = () => {
    const history = useHistory();
    const [cart, setCart] = useState(new Cart());
    const [receipt, setReceipt] = useState(undefined);
    const [error, setError] = useState(undefined);
    let [stock, setStock] = useState(undefined);

    const getPayload = async(code) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_AXICHAIN_API}/rmc/stock-items/${code}`);

            console.log("RESPONSE", await response.clone().json());
            if (!!response.ok) {
                let result = await response.json();

                return result;
            } else {
                return {
                    type: 'ERROR',
                    name: 'Network Error',
                    description: response.statusText
                }
            }
        } catch (e) {
            return {
                type: 'ERROR',
                name: 'Network Error',
                description: e
            };
        }

    }

    const getStock = async (code) => {
        let result = await getPayload(code);


        if (result.type === 'STOCK') {
            setStock(result);
        } else {
            setStock({});
        }
    };

    useEffect(() => {
        getStock('RMC_001');
    }, []);


    console.log("HISTORY is", history);
    console.log("stock", stock);
    const onSuccess = (value) => {
        setReceipt(value);
        history.push('/reservation/success')
    }

    const onFailure = (value) => {
        setError(value);
        history.push('/reservation/failure')
    }
    return (<Switch>
        <Route path='/checkout'>
            <FullCheckoutPage cart={cart} onSuccess={onSuccess} onFailure={onFailure}></FullCheckoutPage>
        </Route>
        <Route path='/checkout-deposit'>
            <CheckoutPage cart={cart} onSuccess={onSuccess} onFailure={onFailure}></CheckoutPage>
        </Route>
        <Route path='/reservation/success'>
            <ReservationCompletePage receipt={receipt}></ReservationCompletePage>
        </Route>
        <Route path='/reservation/failure'>
            <ReservationFailedPage error={error}></ReservationFailedPage>
        </Route>
        <Route path='/qrcode/:code'>
            <BlockchainViewerPage/>
        </Route>

        <Route path='/'>
            <MainPageComponent cart={cart} stock={stock}></MainPageComponent>
        </Route>
    </Switch>);
}

export default App;
