export interface CartItem {
    code: string;
    name: string;
    description: string;
    quantity: number;
    unitPrice: number;
}

const CART_KEY = 'cart';

export class Cart {
    items: Array<CartItem> = [];

    constructor() {
        this.load();
    }

    clear = () => {
        this.items= [];
    }

    addItem = (item: CartItem) => {
        if (!!this.items.find((i) => i.code === item.code)) {
            this.items = this.items.map((i) => (i.code === item.code ? {...item} : i));
        } else {
            this.items = [...this.items, {...item}];
        }
    }

    removeItem = (item: CartItem) => this.items.filter((i) => i.code !== item.code);

    updateItem = (item: CartItem) => this.addItem(item);

    load = () => {
        const cartJson = window.localStorage.getItem('cart');
        this.items = (!!cartJson ? JSON.parse(cartJson) : []);
    }

    save = () => {
        window.localStorage.setItem(CART_KEY, JSON.stringify(this.items ?? []));
    }
}