import React from 'react';
import './footer.styles.scss';
import poweredAxi from '../../assets/poweredAxichain.svg';

export const Footer = () => {

    return (

        <div className="footerSection">
        <div className="myContainer">
          <p className="col-md-8 col-7"><span className="mr-3">Red Meat Company © 2020</span> | <a href="privacy.html"
              className="ml-3 mr-3">Privacy</a> | <a href="terms.html" className="ml-3 mr-3">T&amp;C</a> | </p>
          <a href="https://www.axichain.io" target="_blank"><img className="img-fluid" src={poweredAxi}
              alt="RMC logo" style={{position: 'absolute', top: '0px', right: '0px', marginBottom: '20px', width: '300px',}}/></a>
        </div>
      </div>

    );

}