import React from 'react';
import './header.styles.scss';
import logo from '../../assets/rmcLogo.svg';
import banner from '../../assets/promoBanner.png';

export const Header = () => {

    return (
        <div className="header">
        <div className="container">
          <br></br><br></br><br></br>
          <div className="text-center col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1">
            <a href="index.html"><img className="img-fluid" src={logo} alt="RMC logo"
                style={{position: 'relative', top: '10px', marginBottom: '20px', width: '60%'}}/></a>
            <img className="img-fluid" src={banner} alt="banner" style={{position: 'relative', top: '10px', maxWidth: '100%'}}/>
          </div>
          <br></br><br></br><br></br>
        </div>
      </div>
    );

}