import React from "react";
import { useParams } from 'react-router-dom';
import IFrame from 'react-iframe'
import logo from '../../assets/rmcLogo.svg';
import poweredAxi from '../../assets/poweredAxichain_white.svg';
import traceImg from '../../assets/trace2.svg';

export const BlockchainViewerPage = () => {
const { code } = useParams();

return (
<div className={'page'}>
    <div className="header" style={{padding: "20px 0px"}}>
        <div className="container">
       
            <div className="text-center col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-8 offset-2">
                <a href="index.html"><img className="img-fluid" src={logo} alt="RMC logo"
                        style={{position: 'relative', top: '10px', marginBottom: '10px', width: '40%'}} /></a>
                         <h4>Traceability</h4>
                    <img src={traceImg} alt="trace images" className="img-fluid" style={{width: '100%',}} />

            </div>
       
        </div>
    </div>
    <div className={'blockChainWrapper'}>
        <IFrame url={`${process.env.REACT_APP_BLOCKCAIN_VIEWER}/mnvd/${code}?headless=true`} width='100%' height='900px' id="myId"
            className={'myClassname'} display="initial" position="relative" />


        <div className="footerSection" style={{bottom: '6px'}}>
            <div className="myContainer">
                <p className="col-md-8 col-7"><span className="mr-3">Red Meat Company © 2020</span> | <a
                        href="privacy.html" className="ml-3 mr-3">Privacy</a> | <a href="terms.html"
                        className="ml-3 mr-3">T&amp;C</a> | </p>
                <a href="https://www.axichain.io" target="_blank"><img className="img-fluid" src={poweredAxi}
                        alt="RMC logo"
                        style={{position: 'absolute', top: '0px', right: '0px', marginBottom: '20px', width: '300px',}} /></a>
            </div>
        </div>


    </div>
</div>
)
}