import NopePrimitive from './NopePrimitive';

class NopeAny extends NopePrimitive<any> {
  public validate(entry?: any, context?: object | undefined): any | undefined {
    const value = !!entry ? String(entry) : entry;

    return super.validate(value, context);
  }
}

export default NopeAny;
