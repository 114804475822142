import React from 'react';
import { useCacheValue } from '@kuzmycz/react-cache';
import { Field } from '@kuzmycz/react-form-ula';
import TextFormField from "../from-fields/text-form-field/text-form-field.component";


export const ShippingAddress = () => {
    const [shippingSame, _] = useCacheValue(`values.shippingSame`);

    return (
        <React.Fragment>
            <div className="row">
                <div className="container">
            <label><Field name={'shippingSame' } type={'checkbox'}/> Shipping is same as billing </label>
            <br></br><br></br>
            </div>
            </div>

            {!shippingSame && <React.Fragment><h3>Shipping Details</h3>
                <div className='shipping-details'>
                    <div className="firstNameField">
                        <TextFormField placeholder='First Name' name='shippingFirstName' type='text'/>
                    </div>
                    <div className="lastNameField"><TextFormField placeholder='Last Name' name='shippingLastName' type='text'/></div>
                    <div className="streetField"><TextFormField placeholder='Street' name='shippingStreet' type='text'/></div>
                    <div className="cityField"><TextFormField placeholder='City' name='shippingCity' type='text'/></div>
                    <div className="stateField"><TextFormField placeholder='State' name='shippingState' type='text'/></div>
                    <div className="postcodeField"><TextFormField placeholder='Postcode' name='shippingPostcode' type='text'/></div>
                    <div className="phoneField"><TextFormField placeholder='Phone' name='shippingPhone' type='text'/></div>
                </div>
            </React.Fragment>}
        </React.Fragment>);

}