import React from "react";
import {Nope} from '../../util/nope-validator'

export const validator = Nope.object().shape({
        email: Nope.string().required('Email address is required').email("Not a valid email").atMost(100),
        firstName: Nope.string().required('First name is required').atMost(100),
        lastName: Nope.string().required('Last name is required').atMost(100),
        street: Nope.string().required('Street is required').atMost(100),
        city: Nope.string().required('City is required').atMost(100),
        state: Nope.string().required('State is required').atMost(100),
        postcode: Nope.string().required('Postcode is required').atMost(100),
        phone: Nope.string().required('Phone is required').atMost(100),
        shippingSame: Nope.boolean().required(),
        shippingFirstName: Nope.string().when('shippingSame', {
                is: false,
                then: Nope.string().required('Shipping First name is required').atMost(100)
            }),
        shippingLastName: Nope.string().when('shippingSame', {
                    is: false,
                    then: Nope.string().required('Shipping Last name is required').atMost(100)
            }),
        shippingStreet: Nope.string().when('shippingSame', {
                    is: false,
                    then: Nope.string().required('Shipping Street is required').atMost(100)
            }),
        shippingCity: Nope.string().when('shippingSame', {
                    is: false,
                    then: Nope.string().required('Shipping City is required').atMost(100)
            }),
        shippingState: Nope.string().when('shippingSame', {
                    is: false,
                    then: Nope.string().required('Shipping State is required').atMost(100)
            }),
        shippingPostcode: Nope.string().when('shippingSame', {
                    is: false,
                    then: Nope.string().required('Shipping Postcode is required').atMost(100)
            }),
        shippingPhone: Nope.string().when('shippingSame', {
                is: false,
                then: Nope.string().required('Shipping Phone is required').atMost(100)
        }),
        shippingInstruction: Nope.string().atMost(500),
});