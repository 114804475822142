import {Field} from "@kuzmycz/react-form-ula";
import '../../pages/main-page/main-page.styles.scss'

export const ProductLine = ({name}) => {

    return (
        <div className={'product-row'}>
            <div className={'productNameWrapper'}>
            <div className={'fieldOne'}><Field name={`${name}.name`} type='text' readOnly='true' /></div>
            <div className={'fieldTwo'}><Field name={`${name}.description`} type='text' readOnly='true' /></div>
            </div>
            <div className={'fieldThree'}><span>Qty: </span><Field name={`${name}.quantity`} type='number' /></div>
            {/*<div className={'fieldFour'}><span>Price: $</span><Field name={`${name}.unitPrice`} type='text' readOnly='true' /><span className={'withDepostiOffer'}>* with deposit offer</span></div>*/}
        </div>);
}
