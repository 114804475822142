import React from 'react';
import { Form } from '@kuzmycz/react-form-ula';
import {ProductLine} from "../../components/product-line/product-line.component";
import './main-page.styles.scss'
import { useHistory } from "react-router-dom";
import { Header } from "../../components/header/header.component";
import { Body } from "../../components/body/body.component";
import { Footer } from "../../components/footer/footer.component";

const FULL_PAYMENT = 'FULL_PAYMENT';
const DEPOSIT = 'DEPOSIT';

const INITIAL_DATA = {
    RMC_FAMILY_03: {
        code: 'RMC_FAMILY_03',
        name: 'FAMILY 3KG PACK',
        description: 'ASSORTED CUTS: ROAST, STEAK, SAUSAGE, MINCE',
        quantity: 0,
        unitPrice: 71.85
    },
    RMC_FAMILY_05: {
        code: 'RMC_FAMILY_05',
        name: 'FAMILY 5KG PACK',
        description: 'ASSORTED CUTS: ROAST, STEAK, SAUSAGE, MINCE',
        quantity: 0,
        unitPrice: 119.75
    },
    RMC_FAMILY_10: {
        code: 'RMC_FAMILY_10',
        name: 'FAMILY 10KG PACK',
        description: 'ASSORTED CUTS: ROAST, STEAK, SAUSAGE, MINCE',
        quantity: 0,
        unitPrice: 239.50
    }
};

const initialState = (cart) => {
    let state = {...INITIAL_DATA};
    if (cart.items.length > 0) {
        cart.items.forEach((i) => {
            let value = state[i.code];
            if (!!value) value.quantity = i.quantity;
        });
    }
    return state;
}

const MainPageComponent = ({ cart, stock }) => {
    let history = useHistory();
    let submitMode = undefined;

    console.log("STOCK", stock);

    const toCartItem = (form) => {
        return {
            code: form.code,
            name: form.name,
            description: form.description,
            quantity: Number(form.quantity),
            unitPrice: form.unitPrice
        };
    }
    const submitHandler = (values) => {
        cart.clear();
        if (values.RMC_FAMILY_03.quantity > 0) {
            cart.addItem(toCartItem(values.RMC_FAMILY_03))
        }
        if (values.RMC_FAMILY_05.quantity > 0) {
            cart.addItem(toCartItem(values.RMC_FAMILY_05))
        }
        if (values.RMC_FAMILY_10.quantity > 0) {
            cart.addItem(toCartItem(values.RMC_FAMILY_10));
        }
        cart.save();

        if (submitMode === FULL_PAYMENT) {
            history.push('/checkout');
        } else {
            history.push('/checkout-deposit');
        }
    };

    const fullPayment = () => {
        submitMode = FULL_PAYMENT;
    }
    const deposit = () => {
        submitMode = DEPOSIT;
    }

    return (<React.Fragment>
        <Header />
        <Body />
        <Form initialValues={initialState(cart)} onSubmit={submitHandler}>
        <div className={'element-layout'}>
              <div className={'myContainer'}>
                    <div className={'row mobileRow'}>
                        <div className={`col-md-10 productLineWrapper offset-md-2`}>
                            <ProductLine name={'RMC_FAMILY_03'} />

                            <div className={'buttonSection'}>
                            <button type='submit' disabled className={`checkoutButton btn`} >PAY NOW</button>
                            {/*<div className={'line'}></div>
                            <button type='submit' disabled={(stock?.unitsRemaining ?? 0) < 3} className={`checkoutButton btn`} onClick={deposit}>DEPOSIT NOW</button>*/}
                            <div className={'line'}></div>
                            <a href="https://redmeatcompany.us7.list-manage.com/subscribe/post?u=7320305202b9751a7870dbdcf&id=7ad172461e"
                                target="_blank" className={'regButton'}><button type="button" className="btn"
                                    style={{background: 'transparent', color: '#ffffff', border: '1px solid #ffffff', margin: '10px', paddingTop: '17px', paddingBottom: '17px',}}>REGISTER
                                    INTEREST
                                </button></a>
                            </div>


                        </div>
                    </div>
                </div>
                <div className={'myContainer'}>
                    <div className={'row mobileRow'}>
                        <div className={`col-md-10 productLineWrapper offset-md-2`}>
                            <ProductLine name={'RMC_FAMILY_05'} />

                            <div className={'buttonSection'}>
                            {/*<button type='submit' className={`checkoutButton btn`} onClick={fullPayment}><br></br>PAY NOW<span>Limited<br></br>stock available</span></button>*/}
                            <button type='submit' disabled className={`checkoutButton btn`} >PAY NOW</button>
                            {/*<div className={'line'}></div>
                            <button type='submit' disabled={(stock?.unitsRemaining ?? 2) < 1} className={`checkoutButton btn`} onClick={deposit}>DEPOSIT NOW</button>*/}
                        <div className={'line'}></div>
                            <a href="https://redmeatcompany.us7.list-manage.com/subscribe/post?u=7320305202b9751a7870dbdcf&id=7ad172461e"
                                target="_blank" className={'regButton'}><button type="button" className="btn"
                                    style={{background: 'transparent', color: '#ffffff', border: '1px solid #ffffff', margin: '10px', paddingTop: '17px', paddingBottom: '17px',}}>REGISTER
                                    INTEREST
                                </button></a>
                            </div>


                        </div>
                    </div>
                </div>
                <div className={'myContainer'}>
                    <div className={'row mobileRow'}>
                        <div className={`col-md-10 productLineWrapper offset-md-2`}>
                            <ProductLine name={'RMC_FAMILY_10'} />
                            <div className={'buttonSection'}>
                            {/*<button type='submit' className={`checkoutButton btn`} onClick={fullPayment}><br></br>PAY NOW<span>Limited<br></br>stock available</span></button>*/}
                            <button type='submit' disabled className={`checkoutButton btn`} >PAY NOW</button>
                            {/*<div className={'line'}></div>
                            <button type='submit' disabled={(stock?.unitsRemaining ?? 0) < 2} className={`checkoutButton btn`} onClick={fullPayment}>DEPOSIT NOW</button>*/}
                    <div className={'line'}></div>
                            <a href="https://redmeatcompany.us7.list-manage.com/subscribe/post?u=7320305202b9751a7870dbdcf&id=7ad172461e"
                                target="_blank" className={'regButton'}><button type="button" className="btn"
                                    style={{background: 'transparent', color: '#ffffff', border: '1px solid #ffffff', margin: '10px', paddingTop: '17px', paddingBottom: '17px',}}>REGISTER
                                    INTEREST
                                </button></a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        <Footer />
    </React.Fragment>);
}
export default MainPageComponent;